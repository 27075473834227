<script>
  import { onMount } from "svelte";
  export let leaderboardPromise = getLeaderboard();
  export let error;
  export let leaderboard;
  let cursor;

  async function getLeaderboard(cursor){
    let url = "/api/leaderboard";
    if(cursor) url += `?next=${cursor}`;
    const res = await fetch(url);
    return res.json();
  }

	onMount(async () => {
    try{
      const res = await leaderboardPromise;
      leaderboard = res.leaderboard;
      cursor = res.next;
    } catch(err){
      error = err;
    }
  });

  async function loadMorePlayers(){
    try{
      const res = await getLeaderboard(cursor);
      leaderboard = [...leaderboard, ...res.leaderboard];
      cursor = res.next;
      error = undefined;
    } catch(err){
      error = err;
    }
  }

  function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  }
  
</script>

<main class="scrims-ladder">

  <div class="ladder-nav">
    <h1>Trivial Master - Leaderboard</h1>
  </div>
  <div class="ladder-nav--results">
    <div class="ladder-nav--results-col col1">
      <label>Rank</label>
    </div>
    <div class="ladder-nav--results-col col3">
      <label>Jugador</label>
    </div>
    <div class="ladder-nav--results-col col4">
      <label>Puntos</label>
    </div>
  </div>
  {#if leaderboard}
    {#each leaderboard as player}
      <div class="ladder-nav--results-players">
        <div class="results-col col1">
          <span class="results-rank"><span class="positions">{player.rank}</span></span>
        </div>
        <div class="results-col col2">
          <span class="results-team"><img width="32" height="32" src={player.avatar} alt={player.playerName}></span>
        </div>
        <div class="results-col col3">
          <span class="results-p1">{player.playerName}</span>
        </div>
        <div class="results-col col4">
          <span class="results-p2">{formatNumber(player.score)}</span>
        </div>
      </div>
    {/each}
  {:else}
    <div class="ladder-nav--results-players"><p style="color: white">Loading...</p></div>
  {/if}
  {#if error}
    <div class="ladder-nav--results-players"><p style="color: red">{error}</p></div>
  {/if}
  <div class="ladder-nav--results-players">
    <span class="more" on:click={loadMorePlayers} style="color: white; cursor: pointer; margin: 0 auto">Más</span>
  </div>
</main>

<style>

  .ladder-nav {
    background:#5055CB;
    border-radius:10px;
    border:1px solid #686EF9;
  }

  .ladder-nav h1 {
    line-height:1;
    font-size:20px;
    color:#c2ceff!important;
    font-weight:bold;
    text-transform:uppercase;
  }
 
  /* Results Nav */
  .ladder-nav--results, .ladder-nav--results-players {
    display:flex;
    flex-wrap:wrap;
    padding:15px 25px;
  }
  .ladder-nav--results label {
    color:#747474;
    font-size:18px;
    font-weight:bold;
  }

  .ladder-nav--results-col.col1{
    flex: 0 0 5em;
  }
  .ladder-nav--results-col.col3{
    flex: 1;
  }
  .ladder-nav--results-col.col4{
    flex: 0 0 5em;
  }

  .results-col.col1 {
    flex: 0 0 45px;
  }
  .results-col.col2 {
    flex: 0 0 45px;
  }
  @media only screen and (min-width: 450px) {
    .results-col.col1 {
      flex: 1;
      max-width: 120px;
    }
  }
  .results-col.col3 {
    flex: 1;
  }
  .results-col.col4 {
    flex: 0 0 4em;
  }
  
  .ladder-nav--results {
      margin:5px 0px;
  }
  .ladder-nav--results-players {
      background:#1d1d1d;
      border-radius:10px;
      margin:5px 0px;
      align-items:center;
  }
  .ladder-nav--results-players:nth-of-type(1) {
      color:#686EF9;
  }
  .results-col {
      font-size:18px;
      color:#747474;
      font-weight:light;
  }
  .results-rank {
      display:block;
      background:#686EF9;
      color:white!important;
      font-weight:bold;
      width:34px;
      height:34px;
      text-align:center;
      border-radius:50%;
  }
  .positions {
      vertical-align:middle;
  }

  .ladder-nav--results-players:hover {
      background:#1A1A1A;
  }
    
</style>